import { FlexBox } from '@rario/shared-components'
import { FC, useEffect } from 'react'

const CAPTCHA_ACTION = 'USER_LOGIN'

export const GoogleRecaptcha: FC<{
  setIsCaptchaVerified: (value: boolean) => void
  setCaptchaToken: (value: string) => void
  setCaptchaAction: (value: string) => void
}> = ({ setCaptchaToken, setIsCaptchaVerified, setCaptchaAction }) => {
  function reCaptchaSuccessCallback(captchaToken: string) {
    setCaptchaToken(captchaToken)
    setIsCaptchaVerified(true)
    setCaptchaAction(CAPTCHA_ACTION)
  }

  function reCaptchaExpiredCallback() {
    setCaptchaToken('')
    setIsCaptchaVerified(false)
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://www.google.com/recaptcha/api.js'
    script.id = 'recatchaScript'
    script.async = true
    script.defer = true
    document.body.appendChild(script)

    if (window) {
      // @ts-ignore
      window.reCaptchaSuccessCallback = reCaptchaSuccessCallback
      // @ts-ignore
      window.reCaptchaExpiredCallback = reCaptchaExpiredCallback
    }
    return () => {
      const rechaptchaScipt = document.getElementById('recatchaScript')
      rechaptchaScipt && document.body.removeChild(rechaptchaScipt)
      if (window) {
        // @ts-ignore
        window.reCaptchaSuccessCallback = undefined
        // @ts-ignore
        window.reCaptchaExpiredCallback = undefined
      }
    }
  }, [])
  const { RECAPTCHA_SITEKEY = '' } = process.env
  return (
    <FlexBox>
      <form action="" method="POST">
        <div
          className="g-recaptcha"
          data-sitekey={RECAPTCHA_SITEKEY}
          data-callback="reCaptchaSuccessCallback"
          data-expired-callback="reCaptchaExpiredCallback"
          data-action={CAPTCHA_ACTION}
        ></div>
        <br />
        <input type="submit" value="Submit" hidden />
      </form>
    </FlexBox>
  )
}
