import { Button, FlexBox, TextField, theme, trackEvent } from '@rario/shared-components'
import React, { FC, useState } from 'react'
import { CustomInput, CustomInputContainer, CustomLabel } from './LoginSignupForm.style'
import { useForm } from 'react-hook-form'
import { ConnectionTypeEnum, LoginSignupFormData, LoginSignupInput } from 'interfaces/auth'
import { EMAIL_REGEX } from 'constant/transaction'

import PasswordStrengthList from './PasswordStrengthList'
import EyeIcon from 'assets/svg/EyeIcon'
import EyeNotIcon from 'assets/svg/EyeNotIcon'
import {
  DreamAuthResources,
  DreamAuthresponseType,
  UTM_COOKIES,
  VerifyEmailResetActionType,
} from 'constant'
import { verifyEmailResetPasswordStep1, userLogin, userSignup } from 'api/Auth.api'
import { IApiException } from 'api/Api.exception'
import Toast from 'components/Toast'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'
import { customResetPasswordText, resetPasswordText } from 'components/GTContest/constant'
import { IS_RECAPTCHA_DISABLED } from 'config'
import { usePasswordValidator } from 'hooks/usePasswordValidator'
import { GoogleRecaptcha } from './GoogleRecaptcha'
import { CampaignTypeEnum } from 'interfaces/wallet'

const LoginSignupForm: FC<{
  isSignup?: boolean
  redirectTo?: string
}> = ({ isSignup = false, redirectTo }) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm<LoginSignupInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(IS_RECAPTCHA_DISABLED)
  const [loadingSuccess, setLoadingSuccess] = useState(false)
  const [captchaToken, setCaptchaToken] = useState('')
  const [captchaAction, setCaptchaAction] = useState('')
  const [values, setValues] = useState({
    password: '',
    email: '',
  })
  const { isPasswordValid, validatePassword, validatePasswordPattern, state } =
    usePasswordValidator()
  const [showPasswordSuggestion, setShowPasswordSuggestion] = useState(false)
  const [cookies, removeCookie] = useCookies([
    UTM_COOKIES.UTM_SOURCE,
    UTM_COOKIES.UTM_CAMPAIGN,
    UTM_COOKIES.UTM_CONTENT,
    UTM_COOKIES.UTM_MEDIUM,
  ])
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.name === 'password' && validatePassword(event.target.value)
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  const initiateEmailVerification = async (email: string, action: string) => {
    const formData: { email: string; action: string } = {
      email,
      action,
    }
    try {
      await verifyEmailResetPasswordStep1(formData)
      setIsLoading(false)
      setLoadingSuccess(true)
    } catch (e) {
      const error = e as IApiException
      const errorResponse = error.response ? error.response : { message: error.message }
      setIsLoading(false)
      Toast({
        message: errorResponse.message,
      })
    }
  }

  const router = useRouter()
  const onSubmit = async (data: { email: string; password: string }) => {
    setIsLoading(true)
    const { email, password } = data
    const utmData = {
      utmSource: cookies[UTM_COOKIES.UTM_SOURCE]?.toString(),
      utmMedium: cookies[UTM_COOKIES.UTM_MEDIUM]?.toString(),
      utmCampaign: cookies[UTM_COOKIES.UTM_CAMPAIGN]?.toString(),
      utmContent: cookies[UTM_COOKIES.UTM_CONTENT]?.toString(),
    }
    const connectionName = {
      connection: ConnectionTypeEnum.EMAIL_PASSWORD,
    }
    const formData: LoginSignupFormData = {
      responseType: DreamAuthresponseType,
      username: email,
      password,
      captchaToken,
      captchaAction,
      resources: DreamAuthResources,
      ...(isSignup && connectionName), // send connection name on signup only
      ...(isSignup && utmData), // send utm data
    }
    if (isSignup) {
      trackEvent('Section Interacted', 'click', {
        sub_category: `singup-email_form`,
        category: isSignup ? 'signup' : 'login',
        click_text: 'Signup',
        click_type: 'button',
        click_action: 'signup',
        view_type: 'default',
        type: 'form',
      })
      try {
        await userSignup(formData)
        setIsLoading(false)
        setLoadingSuccess(true)

        initiateEmailVerification(email, VerifyEmailResetActionType.EMAIL) // Email verification start
        reset({})
        Toast({
          type: 'success',
          message: "We've sent you an email to verify your account. Please check your inbox.",
        })
        ;(window as any).location.href = '/confirmation-email'
      } catch ({ response: e }: any) {
        const error = e as IApiException
        const errorResponse = error.response ? error?.response : { message: error.message }
        setIsLoading(false)
        setLoadingSuccess(false)
        Toast({
          message: errorResponse.message,
        })
        if (!IS_RECAPTCHA_DISABLED) {
          // @ts-ignore
          grecaptcha?.reset()
          setIsCaptchaVerified(false)
        }
      }
    } else {
      trackEvent('Section Interacted', 'click', {
        sub_category: `login-email_form`,
        category: isSignup ? 'signup' : 'login',
        click_text: 'Login',
        click_type: 'button',
        click_action: 'login',
        view_type: 'default',
        type: 'form',
      })
      try {
        await userLogin(formData, redirectTo)
        if (redirectTo) {
          ;(window as any).location.href = redirectTo
        } else {
          ;(window as any).location.href = '/'
        }
      } catch ({ response: e }: any) {
        setIsLoading(false)
        setLoadingSuccess(false)
        const error = e as IApiException
        const errorResponse = error?.response ? error?.response : { message: error?.message }
        if (errorResponse?.message === resetPasswordText) {
          Toast({
            message: customResetPasswordText,
            toastDismissTime: 8000,
          })
          if (cookies[UTM_COOKIES.UTM_MEDIUM] === CampaignTypeEnum.REFERRAL) {
            removeCookie(UTM_COOKIES.UTM_MEDIUM, { path: '/' })
            removeCookie(UTM_COOKIES.UTM_SOURCE, { path: '/' })
          }
          router.push('/forgot-password')
        } else {
          Toast({
            message: errorResponse?.message,
          })
          if (!IS_RECAPTCHA_DISABLED) {
            // @ts-ignore
            grecaptcha?.reset()
            setIsCaptchaVerified(false)
          }
        }
      }
    }
  }

  const trackFormEvent = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    trackEvent('Section Interacted', 'click', {
      sub_category: `${isSignup ? 'signup' : 'login'}-email_form`,
      category: isSignup ? 'signup' : 'login',
      click_text: event.target.name,
      click_type: 'text_box',
      click_action: isSignup ? 'signup' : 'login',
      view_type: 'default',
      type: 'form',
    })
  }

  const handleOnBlur = () => {
    if (values.password.length === 0) {
      setShowPasswordSuggestion(false)
    } else {
      setShowPasswordSuggestion(true)
    }
  }

  const isButtonDisabled = !isValid || !isCaptchaVerified || (isSignup && !isPasswordValid())

  return (
    <>
      <FlexBox flexDirection="column" width="100%" pb={5}>
        {errors.email && (
          <TextField
            fontWeight={400}
            fontSize={'12px'}
            lineHeight={'14.22px'}
            color={theme.colors.atomicTangerine}
            pb={3}
          >
            {errors.email?.message}
          </TextField>
        )}
        <CustomInputContainer>
          <CustomInput
            className={errors.email ? 'error customInput' : 'customInput'}
            // autoComplete={isSignup ? 'off' : 'on'}
            onFocus={trackFormEvent}
            id="email"
            {...register('email', {
              onChange: handleOnChange,
              required: 'Please enter email',
              pattern: {
                value: EMAIL_REGEX,
                message: 'Please enter a valid email',
              },
            })}
            placeholder="Enter email*"
          />
          <CustomLabel
            htmlFor="email"
            className={errors.email ? 'error customLabel' : 'customLabel'}
          >
            Email*
          </CustomLabel>
        </CustomInputContainer>
        <>
          <FlexBox pt={4} flexDirection={'column'}>
            {errors.password && (
              <TextField
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'14.22px'}
                color={theme.colors.atomicTangerine}
                pb={3}
              >
                {errors.password.message}
              </TextField>
            )}
            <CustomInputContainer>
              <CustomInput
                id="password"
                className={errors.password ? 'error customInput' : 'customInput'}
                autoComplete="new-password"
                onFocus={(event) => {
                  trackFormEvent(event)
                  setShowPasswordSuggestion(true)
                }}
                type={showPassword ? 'text' : 'password'}
                {...register('password', {
                  onChange: handleOnChange,
                  onBlur: handleOnBlur,
                  required: 'Please enter password',
                  validate: {
                    matchPattern: (value) =>
                      isSignup ? validatePasswordPattern(value) : undefined,
                  },
                })}
                placeholder="Enter password*"
              />
              <CustomLabel
                htmlFor="password"
                className={errors.password ? 'error customLabel' : 'customLabel'}
              >
                Password*
              </CustomLabel>
              <FlexBox
                onClick={() => setShowPassword(!showPassword)}
                position={'absolute'}
                right={3}
                top={'12px'}
                cursor="pointer"
              >
                {!showPassword ? (
                  <EyeIcon fill="rgba(255,255,255,0.4)" />
                ) : (
                  <EyeNotIcon fill="rgba(255,255,255,0.4)" />
                )}
              </FlexBox>
            </CustomInputContainer>
          </FlexBox>
          {isSignup && showPasswordSuggestion && <PasswordStrengthList state={state} />}
        </>
      </FlexBox>
      {!IS_RECAPTCHA_DISABLED && (
        <GoogleRecaptcha
          setCaptchaToken={setCaptchaToken}
          setCaptchaAction={setCaptchaAction}
          setIsCaptchaVerified={setIsCaptchaVerified}
        />
      )}
      <FlexBox justifyContent={'space-between'} alignItems={'center'}>
        <Button
          variant={'dynamic'}
          colorVariant={isButtonDisabled ? 'whiteFilled' : 'primaryFilled'}
          fontSize={'14px'}
          disabled={isButtonDisabled}
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          success={loadingSuccess}
          width={'50%'}
        >
          {!isSignup ? 'LOGIN' : 'SIGNUP'}
        </Button>
        {!isSignup && (
          <FlexBox
            onClick={() => {
              trackEvent('Section Interacted', 'click', {
                sub_category: `forgot-password-link-text`,
                category: isSignup ? 'signup' : 'login',
                click_text: 'Forgot password?',
                click_type: 'text_url',
                click_action: 'login',
                view_type: 'default',
                type: 'form',
              })
              router.push('/forgot-password')
            }}
            borderBottomWidth={1}
            borderBottomColor={theme.colors.white}
            pb={'1px'}
            borderBottomStyle={'solid'}
            cursor="pointer"
          >
            <TextField
              fontWeight={600}
              fontSize={'14px'}
              lineHeight={'24px'}
              textTransform="uppercase"
              fontFamily={theme.fonts.primary}
              pb={0}
            >
              FORGOT PASSWORD
            </TextField>
          </FlexBox>
        )}
      </FlexBox>
    </>
  )
}

export default LoginSignupForm
