const GradientLineLeft: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
}> = ({ className = '', width = '100', height = '1' }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 100 1" fill="none">
    <line
      x1="4.37114e-08"
      y1="0.5"
      x2="100"
      y2="0.500009"
      stroke="url(#paint0_linear_953_6113)"
      strokeOpacity="0.4"
    ></line>
    <defs>
      <linearGradient
        id="paint0_linear_953_6113"
        x1="100"
        y1="2.00017"
        x2="0"
        y2="2.00017"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white"></stop>
        <stop offset="1" stopColor="white" stopOpacity="0"></stop>
      </linearGradient>
    </defs>
  </svg>
)

export default GradientLineLeft
