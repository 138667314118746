export type AuthTokenType = {
  accessToken: string
  refreshToken: string
  idToken: string
  tokenType: string
  expiresIn: number
}

export type formDataSocialLoginType = {
  responseType: string
  authorizationToken: string
  idProvider: string
  resources: [string]
  connection: string
}

export type googleAuthTokenFormDataType = {
  code: string
  client_id: string
  client_secret: string
  redirect_uri: string
  grant_type: string
}

export type googleAuthRecaptchaValidatePayload = {
  token: string
  expectedAction: string
  siteKey: string
}

export type googleAuthRecaptchaValidateResponse = {
  name: string
  event: { token: string }
  riskAnalysis: {
    score: number
  }
  tokenProperties: {
    valid: boolean
    invalidReason: string
  }
}

export type EncryptedToken = {
  accessToken: string
  refreshToken: string
  expiresIn: Date
}

export interface LoginSignupInput {
  email: string
  password: string
}

export enum ConnectionTypeEnum {
  // eslint-disable-next-line no-unused-vars
  EMAIL_PASSWORD = 'Username-Password-Authentication',
  // eslint-disable-next-line no-unused-vars
  GOOGLE_OAUTH = 'google-oauth2',
  // eslint-disable-next-line no-unused-vars
  D3 = 'd3',
  // eslint-disable-next-line no-unused-vars
  SMS = 'sms',
}
export interface LoginSignupFormData {
  username: string
  password: string
  captchaToken: string
  captchaAction: string
  responseType: string
  resources: string[]
  utmSource?: string | undefined
  utmMedium?: string | undefined
  utmCampaign?: string | undefined
  utmContent?: string | undefined
  connection?: ConnectionTypeEnum
}

export interface ResetInput {
  password: string
  confirm_password?: string
}

export type LoginSignupPayload = Omit<LoginSignupFormData, 'captchaAction' | 'captchaToken'>

export type verificationResultType = { state: string; hash: string }
export type ResetPasswordFormType = { state: string; hash: string; newPassword: string }
