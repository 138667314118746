import { IPL_BASE_PATH } from 'constant/iplTeamLanding'
import { ModalSectionType } from 'interfaces/rarioLanding'
import { getCDNUrl } from 'utils/utils'

interface contestDataType {
  badge: string
  icon: string
  name: string
  subHeading: string
  ctaText: string
  ctaLink: string
}

export const contestStepsDataGT = [
  {
    badge: '/App/images/gt-contest/must-do.png',
    icon: '/App/images/gt-contest/step-quiz.png',
    name: 'Titans’ Quiz',
    subHeading: 'Test your knowledge of the Gujarat Titans',
    ctaText: 'PLAY NOW',
    ctaLink: `${IPL_BASE_PATH}/gt/level-up/quiz`,
  },
  {
    badge: '/App/images/gt-contest/should-do.png',
    icon: '/App/images/gt-contest/step-referral.png',
    name: 'Friends Referred',
    subHeading: 'Get your friends to sign up for GT Fan Badges',
    backgroundImage: 'gt-contest/step-bg.png',
    ctaText: 'REFER NOW',
    ctaLink: `${IPL_BASE_PATH}/gt/level-up/referral`,
  },
  {
    badge: '/App/images/gt-contest/should-do.png',
    icon: '/App/images/gt-contest/step-social.png',
    name: 'Super Social',
    subHeading: 'Share the love and hit it out of the park!',
    ctaText: 'SHARE NOW',
    ctaLink: `${IPL_BASE_PATH}/gt/level-up/social`,
  },
]

export const contestStepsDataRCB = [
  {
    badge: '/App/images/gt-contest/must-do.png',
    icon: '/App/images/gt-contest/step-quiz.png',
    name: 'RCB’ Quiz',
    subHeading: 'Test your knowledge of the Royal Challengers Bangalore',
    ctaText: 'PLAY NOW',
    ctaLink: `${IPL_BASE_PATH}/rcb/level-up/quiz`,
  },
  {
    badge: '/App/images/gt-contest/should-do.png',
    icon: '/App/images/gt-contest/step-referral.png',
    name: 'Friends Referred',
    subHeading: 'Get your friends to sign up for RCB Fan Badges',
    ctaText: 'REFER NOW',
    ctaLink: `${IPL_BASE_PATH}/rcb/level-up/referral`,
  },
  {
    badge: '/App/images/gt-contest/should-do.png',
    icon: '/App/images/gt-contest/step-social.png',
    name: 'Super Social',
    subHeading: 'Share the love and hit it out of the park!',
    ctaText: 'SHARE NOW',
    ctaLink: `${IPL_BASE_PATH}/rcb/level-up/social`,
  },
]
export const contestStepsDataKKR = [
  {
    badge: '/App/images/gt-contest/must-do.png',
    icon: '/App/images/gt-contest/step-quiz.png',
    name: 'Knight Riders’ Quiz',
    subHeading: 'Test your knowledge of the Knight Riders',
    ctaText: 'PLAY NOW',
    ctaLink: `${IPL_BASE_PATH}/kkr/level-up/quiz`,
  },
  {
    badge: '/App/images/gt-contest/should-do.png',
    icon: '/App/images/gt-contest/step-referral.png',
    name: 'Friends Referred',
    subHeading: 'Get your friends to sign up for KKR Fan Badges',
    ctaText: 'REFER NOW',
    ctaLink: `${IPL_BASE_PATH}/kkr/level-up/referral`,
  },
  {
    badge: '/App/images/gt-contest/should-do.png',
    icon: '/App/images/gt-contest/step-social.png',
    name: 'Super Social',
    subHeading: 'Share the love and hit it out of the park!',
    ctaText: 'SHARE NOW',
    ctaLink: `${IPL_BASE_PATH}/kkr/level-up/social`,
  },
]
export const contestStepsDataPBKS = [
  {
    badge: '/App/images/gt-contest/must-do.png',
    icon: '/App/images/gt-contest/step-quiz.png',
    name: 'PBKS Quiz',
    subHeading: 'Test your knowledge of the Punjab Kings',
    ctaText: 'PLAY NOW',
    ctaLink: `${IPL_BASE_PATH}/pbks/level-up/quiz`,
  },
  {
    badge: '/App/images/gt-contest/should-do.png',
    icon: '/App/images/gt-contest/step-referral.png',
    name: 'Friends Referred',
    subHeading: 'Get your friends to sign up for PBKS Fan Badges',
    ctaText: 'REFER NOW',
    ctaLink: `${IPL_BASE_PATH}/pbks/level-up/referral`,
  },
  {
    badge: '/App/images/gt-contest/should-do.png',
    icon: '/App/images/gt-contest/step-social.png',
    name: 'Super Social',
    subHeading: 'Share the love and hit it out of the park!',
    ctaText: 'SHARE NOW',
    ctaLink: `${IPL_BASE_PATH}/pbks/level-up/social`,
  },
]
export const contestStepData: { [key: string]: contestDataType[] } = {
  GT: contestStepsDataGT,
  RCB: contestStepsDataRCB,
  KKR: contestStepsDataKKR,
  PBKS: contestStepsDataPBKS,
}

const contestCarouselGT: { image: string; name: ModalSectionType }[] = [
  { image: getCDNUrl('/App/images/gt-contest/1-grand-stand-ticket.png'), name: 'MATCH-TICKETS' },
  {
    image: getCDNUrl('/App/images/gt-contest/2-executive-titans-gear-gt-v1.png'),
    name: 'TEAM-GEAR',
  },
  { image: getCDNUrl('/App/images/gt-contest/3-meet-greet-v2.png'), name: 'MEET-&-GREET' },
  { image: getCDNUrl('/App/images/gt-contest/4-2-fan-badges-v2.png'), name: 'FAN-BADGES' },
  { image: getCDNUrl('/App/images/gt-contest/5-super-fan.png'), name: 'SUPERFAN-BENEFITS' },
  { image: getCDNUrl('/App/images/gt-contest/6-all-expense-trip-v2.png'), name: 'PAID-TRIP' },
  { image: getCDNUrl('/App/images/gt-contest/7-vip-box-hospitality.png'), name: 'VIP-BOX' },
  { image: getCDNUrl('/App/images/gt-contest/8-signed-merch.png'), name: 'SIGNED-MERCH' },
  { image: getCDNUrl('/App/images/gt-contest/practice-session.png'), name: 'PRACTICE-SESSION' },
  { image: getCDNUrl('/App/images/gt-contest/11-watch-parties.png'), name: 'WATCH-PARTIES' },
  { image: getCDNUrl('/App/images/gt-contest/13-learn-more.png'), name: 'LEARN-MORE' },
]
const contestCarouselRCB: { image: string; name: ModalSectionType }[] = [
  {
    image: getCDNUrl('/App/images/kkr-contest/1-grand-stand-ticket-rcb.png'),
    name: 'MATCH-TICKETS',
  },
  {
    image: getCDNUrl('/App/images/rcb-contest/2-executive-titans-gear-rcb-v1.png'),
    name: 'TEAM-GEAR',
  },
  { image: getCDNUrl('/App/images/rcb-contest/3-meet-greet-rcb-v1.png'), name: 'MEET-&-GREET' },
  { image: getCDNUrl('/App/images/kkr-contest/4-2-fan-badges-rcb.png'), name: 'FAN-BADGES' },
  { image: getCDNUrl('/App/images/rcb-contest/5-super-fan-rcb-v1.png'), name: 'SUPERFAN-BENEFITS' },
  { image: getCDNUrl('/App/images/kkr-contest/6-all-expense-trip-rcb-v2.png'), name: 'PAID-TRIP' },
  { image: getCDNUrl('/App/images/kkr-contest/7-vip-box-hospitality-rcb.png'), name: 'VIP-BOX' },
  { image: getCDNUrl('/App/images/rcb-contest/8-signed-merch-rcb-v1.png'), name: 'SIGNED-MERCH' },
  {
    image: getCDNUrl('/App/images/rcb-contest/practice-session-rcb-v1.png'),
    name: 'PRACTICE-SESSION',
  },
  {
    image: getCDNUrl('/App/images/rcb-contest/11-watch-parties-rcb-v2.png'),
    name: 'WATCH-PARTIES',
  },
  { image: getCDNUrl('/App/images/kkr-contest/13-learn-more-rcb.png.png'), name: 'LEARN-MORE' },
]

const contestCarouselKKR: { image: string; name: ModalSectionType }[] = [
  {
    image: getCDNUrl('/App/images/kkr-contest/1-grand-stand-ticket-kkr.png'),
    name: 'MATCH-TICKETS',
  },
  {
    image: getCDNUrl('/App/images/kkr-contest/2-executive-titans-gear-kkr-v2.png'),
    name: 'TEAM-GEAR',
  },
  { image: getCDNUrl('/App/images/kkr-contest/3-meet-greet-kkr.png'), name: 'MEET-&-GREET' },
  { image: getCDNUrl('/App/images/kkr-contest/4-2-fan-badges-kkr.png'), name: 'FAN-BADGES' },
  { image: getCDNUrl('/App/images/kkr-contest/5-super-fan-kkr.png'), name: 'SUPERFAN-BENEFITS' },
  { image: getCDNUrl('/App/images/kkr-contest/6-all-expense-trip-kkr-v3.png'), name: 'PAID-TRIP' },
  { image: getCDNUrl('/App/images/kkr-contest/7-vip-box-hospitality-kkr.png'), name: 'VIP-BOX' },
  { image: getCDNUrl('/App/images/kkr-contest/8-signed-merch-kkr.png'), name: 'SIGNED-MERCH' },
  {
    image: getCDNUrl('/App/images/kkr-contest/practice-session-kkr-v2.png'),
    name: 'PRACTICE-SESSION',
  },

  { image: getCDNUrl('/App/images/kkr-contest/11-watch-parties-kkr.png'), name: 'WATCH-PARTIES' },

  { image: getCDNUrl('/App/images/kkr-contest/13-learn-more-kkr.png'), name: 'LEARN-MORE' },
]

const contestCarouselPBKS: { image: string; name: ModalSectionType }[] = [
  {
    image: getCDNUrl('/App/images/pbks-contest/1-grand-stand-ticket-pbks.png'),
    name: 'MATCH-TICKETS',
  },
  {
    image: getCDNUrl('/App/images/pbks-contest/2-executive-titans-gear-pkbs-v2.png'),
    name: 'TEAM-GEAR',
  },
  { image: getCDNUrl('/App/images/pbks-contest/3-meet-greet-pbks.png'), name: 'MEET-&-GREET' },
  { image: getCDNUrl('/App/images/pbks-contest/4-2-fan-badges-pbks.png'), name: 'FAN-BADGES' },
  { image: getCDNUrl('/App/images/pbks-contest/5-super-fan-pbks.png'), name: 'SUPERFAN-BENEFITS' },
  {
    image: getCDNUrl('/App/images/pbks-contest/6-all-expense-trip-pbks-v3.png'),
    name: 'PAID-TRIP',
  },
  { image: getCDNUrl('/App/images/pbks-contest/7-vip-box-hospitality-pbks.png'), name: 'VIP-BOX' },
  { image: getCDNUrl('/App/images/pbks-contest/8-signed-merch-pbks-v2.png'), name: 'SIGNED-MERCH' },
  {
    image: getCDNUrl('/App/images/pbks-contest/practice-session-pbks-v2.png'),
    name: 'PRACTICE-SESSION',
  },
  { image: getCDNUrl('/App/images/pbks-contest/11-watch-parties-pbks.png'), name: 'WATCH-PARTIES' },

  { image: getCDNUrl('/App/images/pbks-contest/13-learn-more-pbks.png'), name: 'LEARN-MORE' },
]

export const contestCaoursels: { [key: string]: { image: string; name: ModalSectionType }[] } = {
  GT: contestCarouselGT,
  RCB: contestCarouselRCB,
  KKR: contestCarouselKKR,
  PBKS: contestCarouselPBKS,
}

export const FirstFoldContent: {
  [key: string]: { image: string; headingText: string; backgroundImage: string }
} = {
  GT: {
    image: 'gt-contest/first-fold.png',
    headingText: 'Unlock the Titans’ Inner Circle Benefits',
    backgroundImage: 'gt-contest/contest-bg.png',
  },
  RCB: {
    image: 'rcb-contest/rcb-first-fold.png',
    headingText: ' Unlock the Challengers’ Inner Circle Benefits',
    backgroundImage: 'rcb-contest/RCBcontestbg.png',
  },
  KKR: {
    image: 'kkr-contest/first-fold.png',
    headingText: 'Unlock the KKR Inner Circle Benefits ',
    backgroundImage: 'kkr-contest/contest-bg-v2.png',
  },
  PBKS: {
    image: 'pbks-contest/pbks-first-fold.png',
    headingText: ' Unlock the Kings’ Inner Circle Benefits',
    backgroundImage: 'pbks-contest/first-fold.png',
  },
}

export const contestSectionBgImage: { [key: string]: string } = {
  GT: 'gt-contest/step-bg.png',
  RCB: 'kkr-contest/contest-image-bacground.png',
  KKR: 'gt-contest/step-bg.png',
  PBKS: 'pbks-contest/step-bg.png',
}

export const getTeamFullName: { [key: string]: string } = {
  GT: 'Gujarat Titans',
  RCB: 'Royal Challengers Bangalore',
  KKR: 'Kolkata Knight Riders',
  PBKS: 'Punjab Kings',
}

export const referralShareText = (iplTeamPage: string) =>
  `I'm trying to own one of these awesome Fan Badge Packs to get into the ${getTeamFullName[iplTeamPage]} Inner Circle. Get in on the action - we could soon be watching live matches together…in the VIP Box!`

export const customResetPasswordText =
  'We have updated our security policies. Please reset your password to continue.'
export const resetPasswordText = 'Password reset is required for user.'
