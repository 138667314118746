import { FlexBox, TextField, theme, trackEvent } from '@rario/shared-components'
import { useGoogleLogin } from '@react-oauth/google'
import GoogleIcon from 'assets/svg/GoogleIcon'
import { FC } from 'react'

const GoogleLoginButton: FC<{ redirectUrl?: string }> = ({ redirectUrl }) => {
  // Google signin method
  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${process.env.RARIO_BASE_URL}/api/authv2/googleauth`,
    state: redirectUrl,
  })

  const handleOnClick = () => {
    googleLogin()
    trackEvent('Section Interacted', 'click', {
      sub_category: `login-social_google`,
      category: 'login',
      click_text: 'continue with Google',
      click_type: 'button',
      click_action: 'login',
      view_type: 'default',
      type: 'form',
    })
  }

  return (
    <FlexBox
      onClick={handleOnClick}
      background={theme.colors.white}
      width={'100%'}
      height={58}
      justifyContent="center"
      alignItems="center"
      borderRadius={10}
      mt={20}
      cursor="pointer"
    >
      <GoogleIcon width="32" height="32" />
      <TextField
        fontSize={'16px'}
        fontWeight={600}
        lineHeight="22px"
        fontFamily={theme.fonts.primary}
        pb={0}
        textTransform="uppercase"
        color={theme.colors.black}
        pl={3}
      >
        continue with Google
      </TextField>
    </FlexBox>
  )
}

export default GoogleLoginButton
