const GradientLineRight: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
}> = ({ className = '', width = '100', height = '1' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 100 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      y1="-0.5"
      x2="100"
      y2="-0.5"
      transform="matrix(-1 8.74228e-08 8.74228e-08 1 100 1)"
      stroke="url(#paint0_linear_953_6114)"
      strokeOpacity="0.4"
    ></line>
    <defs>
      <linearGradient
        id="paint0_linear_953_6114"
        x1="100"
        y1="1.00016"
        x2="8.74374e-08"
        y2="1.00017"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white"></stop>
        <stop offset="1" stopColor="white" stopOpacity="0"></stop>
      </linearGradient>
    </defs>
  </svg>
)

export default GradientLineRight
